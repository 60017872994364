import React, { useState, useEffect } from 'react';
import FallingParticles from './FallingParticles';
import axios from 'axios';
import chroma from "chroma-js";

const App = () => {
  const [points, setPoints] = useState([]);
  const [houses, setHouses] = useState([]);
  const [history, setHistory] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalLength, setTotalLength] = useState(1);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedTags, setSelectedTags] = useState('');
  const [selectedValue, setSelectedValue] = useState(0);
  const [selectedText, setSelectedText] = useState('');

  const currentYear = new Date().getFullYear();

  const totalPoints = points.reduce((total, point) => total + Math.abs(point.totalPoints), 0);
  
  const hasNextPage = totalLength > pageNumber * pageSize;
  const hasPreviousPage = pageNumber > 1;
  const totalPages = Math.ceil(totalLength / pageSize);

  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const backendPort = process.env.REACT_APP_BACKEND_PORT;

  const openPopup = () => setPopupOpen(true);
  const closePopup = () => {
    setPopupOpen(false);
    setSelectedTags(''); // Clear selected tags when closing the popup
    setSelectedText('');
    setSelectedValue(0);
  };

  const handleTagRadioChange = (tag) => {
    setSelectedTags(tag);
  };

  // Fetch points for specific tags
  const fetchPoints = async () => {
    try {
      const response = await axios.get(`${backendURL}/get-house-points`);
      setPoints(response.data);
    } catch (error) {
      console.error('Error fetching points:', error);
    }
  };

  const fetchHouses = async () => {
    try {
      const response = await axios.get(`${backendURL}/get-house-tags`);
      setHouses(response.data);
    } catch (error) {
      console.error('Error fetching points:', error);
    }
  };

  // Fetch points history with paging
  const fetchPointsHistory = async () => {
    try {
      const response = await axios.get(`${backendURL}/get-house-points-history`, {
        params: {
          pageNumber,
          pageSize,
        },
      });
      setHistory(response.data);
    } catch (error) {
      console.error('Error fetching points history:', error);
    }
  };

  const fetchNumberOfRecords = async () => {
    try {
      const response = await axios.get(`${backendURL}/get-row-number`);
      setTotalLength(response.data[0].number);
    } catch (error) {
      console.error('Error fetching points history:', error);
    }
  };

  useEffect(() => {
    fetchHouses();
    fetchPoints();
    fetchNumberOfRecords();
    fetchPointsHistory();
  }, [backendURL, backendPort, pageNumber, pageSize]);

  const handleCommit = async () => {
    try {
      // Commit data to the database
      await axios.post(`${backendURL}/assign-house-point`, {
        tag: selectedTags,
        value: selectedValue,
        text: selectedText,
      });

      // Refresh data
      fetchHouses();
      fetchPoints();
      fetchNumberOfRecords();
      fetchPointsHistory();

      // Close the popup
      closePopup();
    } catch (error) {
      console.error('Error committing data:', error);
    }
  };

  const tagWithMostPoints = points.reduce((maxTag, currentTag) => {
    return currentTag.totalPoints > maxTag.totalPoints ? currentTag : maxTag;
  }, points[0]);

  function createGradient(color) {
      const factor = 0.5
      const lighterColor = chroma(color).brighten(factor).hex()
      const darkerColor = chroma(color).darken(factor).hex()

      const gradient = `linear-gradient(90deg, ${darkerColor} 0%, ${color} 25%, ${lighterColor} 60%, ${color} 75%, ${darkerColor} 100%)`;

      return gradient;
  }

  return (
    <div className='max-width' style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
      <FallingParticles /> 
      <h1 className='center title brick-horizontal-background'>Ranking domów</h1>

      <div className='center margin-bottom margin-top'></div>

      <div className='center'>
        <div className='max-width' style={{ display: 'flex', justifyContent: 'space-around', width: '100%'}}>
          {points.map((point) => (
            <div key={point.tag} className={point.tag === tagWithMostPoints.tag ? 'text lider' : 'text'} style={{ textAlign: 'center' }}>
              <div className='margin-bottom relative'>
                <img className='board' src='/images/board.png'></img>
      
                <div className= 'absolute' style={{display: 'flex', justifyContent: 'center', alignItems: 'center', top: '5.2rem', left: '3.575rem'}}>
                  <img className='house-logo center' src={'/images/' + point.tag + '.png'}></img>
                  <div style={{paddingLeft: '0.325rem'}}> 
                    <p className='text' style={{margin: '0px'}}>{point.name}</p>
                    <p className='text normal-text'>Punkty: {point.totalPoints}</p>
                  </div>
                </div>
              </div>
              <div className="fill-indicator" style={{ display: 'flex', justifyContent: 'space-around', width:'100%'}}>
                <img className='absolute glass-tube' src='/images/glass_tube.png'></img>
                <div style={{ border: '1px solid', borderRadius: '1.625rem', width: '20%', height: '18.2rem', overflow: 'hidden', marginBottom: '0.65rem' }}>
                  <div style={{ height: '18.2rem', background: 'transparent', width: '100%', position: 'relative' }}>
                    <div
                    className="fill-animation"
                      style={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        height: `${(point.totalPoints / totalPoints) * 18.2}rem`,
                        background: createGradient(houses.find(obj => obj.tag === point.tag).color),
                        width: '100%',
                        borderTopLeftRadius: '0.25rem',
                        borderTopRightRadius: '0.25rem',
                        animationFillMode: 'both',
                        animationDelay: '1s',
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>


      <div className='center brick-horizontal-background margin-bottom' style={{display:'flex', justifyContent: 'center', alignContent: 'center'}}>
        <button className='normal-button' style={{margin: 'auto'}} onClick={openPopup}>Przyznaj punkty</button>
      </div>

      <div className='center'>
        <div className='history-background' style={{ padding: '5.2rem', width: '63.7rem', height: '45.5rem',display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
        <h2 className='center title'>Historia punktów</h2>
        <div style={{paddingBottom: '6.5rem'}}></div>
            {history.map((entry) => (
                <div className='text normal-text center' style= {{width: '80%' ,display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 5fr', alignSelf: 'center'}}>
                  <span key={entry.id} style={{gridColumn: '1', textAlign: 'center' }}>{entry.value}</span>
                  <span style={{gridColumn: '2'}} >pkt dla</span>
                  <span style={{color: houses.find(obj => obj.tag === entry.tag).color, gridColumn: '3'}}>{houses.find(obj => obj.tag === entry.tag).name}</span> 
                  <span style={{gridColumn: '4', paddingLeft: '0.65rem'}} >{entry.text}</span>
                </div>
              ))}
        </div>
      </div>

      <div className='center margin-bottom'>
        <div style={{ marginTop: '1.3rem', width: '58.5rem', display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)'}}>
          {hasPreviousPage && <button className='arrow-left' style={{gridColumn: '1', justifySelf: 'center'}} onClick={() => setPageNumber((prev) => prev - 1)}>Poprzednia strona</button>}
          <button className='middle-button disabled' style={{gridColumn: '2', justifySelf: 'center'}}> Strona {pageNumber}/{totalPages} </button>
          {hasNextPage && <button className='arrow-right' style={{gridColumn: '3', justifySelf: 'center'}} onClick={() => setPageNumber((prev) => prev + 1)}>Nastepna strona</button>}
        </div>
      </div>

      <div className='footer center'>
        <span style={{alignSelf:'center'}}>{currentYear} Lobocik</span>
      </div>

      {isPopupOpen && (
        <div style={{ zIndex: "2000",position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', background: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className='list-background popup' style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around'}}>
            <h2 className='title center'>Przyznaj punkty</h2>

            <div style={{display: 'flex', justifyContent: 'space-around'}}>
              {houses.map(function(d, idx){
                return (<label className='radio-checkbox'>
                          <input type="radio" id={d.tag} name={d.tag} value={d.tag} checked={selectedTags === d.tag} onChange={() => handleTagRadioChange(d.tag)} />
                          <img className='house-logo center' src={'/images/' + d.tag + '.png'}></img>
                          <span className='text normal-text center' style={{margin: '0px', width: '100%'}}>{d.name}</span>
                        </label>)
              })}
            </div>
            <br />

            <div className='center text'>
              <span>{selectedValue} pkt</span>
            </div>
            
            <label>
              <input type="range" min="-50" max="50" step="5" value={selectedValue} onChange={(e) => setSelectedValue(Number(e.target.value))} />
            </label>

            <br />
            <div className='center'>
              <textarea className='text-field center text normal-text' placeholder="Podaj powód" type="text" onChange={(e) => setSelectedText(e.target.value)} />
            </div>
            <br />
            
            <div style={{display: 'flex', justifyContent: 'space-around'}}>
              <button className='normal-button' disabled={(selectedTags !== '' && selectedValue !== 0 && selectedText !== '') ? false : true} onClick={handleCommit}>Zapisz</button>
              <button className='normal-button' onClick={closePopup}>Anuluj</button>
            </div>

          </div>
        </div>
      )}
    </div>
  );
};

export default App;