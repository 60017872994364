// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FallingParticles.css */
.falling-particles-container {
  position: absolute;
  width: 90%;
  bottom: auto;
  height: 100%;
  overflow: hidden;
  z-index: 1100;
  pointer-events: none;
  top: 0;
}

.falling-particle {
  position: absolute;
  background-color: #ff9c07;
  animation: fall linear infinite;
  z-index: 1100; /* Set a higher z-index for the particles */
}

@keyframes fall {
  0% {
    transform: translateY(0) scaleY(1);
    opacity: 1;
  }
  100% {
    transform: translateY(100vh) scaleY(0);
    opacity: 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/FallingParticles.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,oBAAoB;EACpB,MAAM;AACR;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,+BAA+B;EAC/B,aAAa,EAAE,2CAA2C;AAC5D;;AAEA;EACE;IACE,kCAAkC;IAClC,UAAU;EACZ;EACA;IACE,sCAAsC;IACtC,UAAU;EACZ;AACF","sourcesContent":["/* FallingParticles.css */\n.falling-particles-container {\n  position: absolute;\n  width: 90%;\n  bottom: auto;\n  height: 100%;\n  overflow: hidden;\n  z-index: 1100;\n  pointer-events: none;\n  top: 0;\n}\n\n.falling-particle {\n  position: absolute;\n  background-color: #ff9c07;\n  animation: fall linear infinite;\n  z-index: 1100; /* Set a higher z-index for the particles */\n}\n\n@keyframes fall {\n  0% {\n    transform: translateY(0) scaleY(1);\n    opacity: 1;\n  }\n  100% {\n    transform: translateY(100vh) scaleY(0);\n    opacity: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
