// FallingParticles.js
import React, { useState, useEffect } from 'react';
import './FallingParticles.css';

const FallingParticles = () => {
  const [particles, setParticles] = useState([]);

  useEffect(() => {
    const particleCount = 30;

    const generateRandomNumber = (min, max) => {
      return Math.random() * (max - min) + min;
    };

    const createParticle = () => {
      return {
        left: `${generateRandomNumber(0, 100)}vw`,
        animationDuration: `${generateRandomNumber(5, 10)}s`,
        animationDelay: `${generateRandomNumber(0, 4)}s`,
        width: `${generateRandomNumber(1, 3)}px`,
        height: `${generateRandomNumber(1, 3)}px`,
      };
    };

    setParticles(Array.from({ length: particleCount }, createParticle));
  }, []);

  return (
    <div className="falling-particles-container">
      {particles.map((particle, index) => (
        <div
          key={index}
          className="falling-particle"
          style={{
            left: particle.left,
            animationDuration: particle.animationDuration,
            animationDelay: particle.animationDelay,
            width: particle.width,
            height: particle.height,
          }}
        ></div>
      ))}
    </div>
  );
};

export default FallingParticles;
